import React from "react";
import classNames from "./CopilotHome.module.scss";
import CopilotPane, { CopilotMode } from "./CopilotPane";

export const CopilotHome = () => {
  return (
    <div className={classNames.appModuleContent}>
      <CopilotPane
        className={classNames.copilotPane}
        copilotMode={CopilotMode.Immersive}
        hideResizeButtons
        largeHeaderText
      />
    </div>
  );
};

export default CopilotHome;
