import React, { useState, useContext, useCallback, CSSProperties, useEffect, useMemo } from "react";
import classNames from "./CopilotPane.module.scss";
import Chat from "../../shared/components/Chat/Chat";
import { ChatRole, IChatMessage } from "../../shared/components/Chat/Chat.types";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { fetchChatResponse, fetchCopilotChatResponse, fetchTopPrompts } from "../../api/radarApi";
import { AppModuleContext } from "../common/AppModule";
import { AppContext } from "../../app/App";
import CopilotCommandBar from "./CopilotCommandBar";
import { shouldRefreshCopilotCloseStatus } from "../common/helper";

export enum CopilotPaneSize {
  min,
  normal,
  large,
  max,
}

export enum CopilotMode {
  Immersive,
  Assistive,
}

export const CopilotChatDataTextMaxSize = 25000;

export interface ICopilotPaneProps {
  className?: string;
  style?: CSSProperties;
  hideResizeButtons?: boolean;
  largeHeaderText?: boolean;
  copilotMode?: CopilotMode;
}

export interface ICopilotPaneStateProps {
  userPhoto: string;
}

export const CopilotPane = (props: ICopilotPaneProps & ICopilotPaneStateProps) => {
  const {
    userPhoto,
    className,
    style,
    largeHeaderText,
    hideResizeButtons,
    copilotMode = CopilotMode.Assistive,
  } = props;
  const [messages, setMessages] = useState<IChatMessage[]>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasUserAskedQuestion, setHasUserAskedQuestion] = useState<boolean>(false);
  const { appState, changeUserSettings } = useContext(AppContext);
  const { useCopilot, userSettings } = appState;
  const { appModuleState } = useContext(AppModuleContext);
  const {
    copilotDataText,
    copilotSuggestions,
    copilotExtraChatMessages,
    copilotInitialMessages,
    copilotInitialMessageCount = 5,
    copilotFollowupMessageCount = 3,
    copilotPaneSize = CopilotPaneSize.normal,
    appModuleName,
  } = appModuleState;
  const [topPrompts, setTopPrompts] = useState<any[]>();

  useEffect(() => {
    setTimeout(() => fetchTopPrompts(appModuleName).then((result: any) => setTopPrompts(result)), 1000);
  }, [appModuleName]);

  const userPrompts = useMemo(() => {
    if (userSettings?.promptHistory && userSettings?.promptHistory[appModuleName]?.length) {
      return userSettings.promptHistory[appModuleName].sort((a, b) =>
        a.useCount > b.useCount || a.lastUsed > b.lastUsed
          ? -1
          : a.useCount < b.useCount || a.lastUsed < b.lastUsed
          ? 1
          : 0
      );
    }
    return null;
  }, [userSettings, appModuleName]);

  const getInitialMessages = useCallback(() => {
    let messages: IChatMessage[] = copilotInitialMessages?.length
      ? copilotInitialMessages
      : copilotSuggestions?.length
      ? initialChatMessagesWithSuggestions.slice()
      : copilotMode === CopilotMode.Immersive
      ? initialImmersiveChatMessages.slice()
      : initialAssistiveChatMessages.slice();

    if (copilotDataText) {
      messages.push({
        role: ChatRole.system,
        content: copilotDataText,
        hidden: true,
      });
    }

    if (copilotExtraChatMessages?.length) {
      messages = messages.concat(copilotExtraChatMessages);
    }

    return messages;
  }, [copilotDataText, copilotSuggestions, copilotExtraChatMessages, copilotInitialMessages, copilotMode]);

  const resetMessages = useCallback(() => {
    if (useCopilot && copilotDataText) {
      setHasUserAskedQuestion(false);
      setMessages(getInitialMessages());
    }
  }, [useCopilot, copilotDataText, getInitialMessages]);

  useEffect(() => {
    setMessages(getInitialMessages());
  }, [getInitialMessages]);

  const getChatResponse = (newMessages: IChatMessage[], doNotUpdateResponse = false) => {
    setLoadingData(true);

    let getChatResponse = copilotMode === CopilotMode.Immersive ? fetchCopilotChatResponse : fetchChatResponse;

    getChatResponse(newMessages)
      .then((result: any) => {
        if (result?.responseText && !doNotUpdateResponse) {
          newMessages.push({ role: ChatRole.assistant, content: result.responseText });
        } else if (result?.error) {
          newMessages.push({ role: ChatRole.assistant, content: `Sorry, error occurred. ${result.error}` });
        }

        if (!doNotUpdateResponse) {
          setMessages(newMessages.slice());
          setHasUserAskedQuestion(true);
        }
      })
      .catch((error) => {
        newMessages.push({ role: ChatRole.assistant, content: `Sorry, error occurred. ${error}` });
        setMessages(newMessages.slice());
      })
      .finally(() => setLoadingData(false));
  };

  const updateUserPromptHistory = (message: string) => {
    // Save user message/prompt history.
    let newUserSettings = userSettings;
    !newUserSettings && (newUserSettings = {});
    !newUserSettings.promptHistory && (newUserSettings.promptHistory = {});
    !newUserSettings.promptHistory[appModuleName] && (newUserSettings.promptHistory[appModuleName] = []);

    let existingPrompt = newUserSettings.promptHistory[appModuleName].find(
      (prompt) => prompt.message.toLowerCase().trim() === message.toLowerCase().trim()
    );

    if (existingPrompt) {
      existingPrompt.lastUsed = new Date().toISOString();
      existingPrompt.useCount = existingPrompt.useCount + 1;
    } else {
      newUserSettings.promptHistory[appModuleName].push({ message, lastUsed: new Date().toISOString(), useCount: 1 });
    }

    changeUserSettings(newUserSettings);
  };

  const onSendRequested = (message: string, doNotUpdateResponse = false) => {
    message = message?.trim();

    if (!message) return;

    let newMessages = messages.slice();
    newMessages.push({ role: ChatRole.user, content: message });

    setMessages(newMessages);
    getChatResponse(newMessages, doNotUpdateResponse);

    setTimeout(() => updateUserPromptHistory(message), 500);
  };

  useEffect(() => {
    if (shouldRefreshCopilotCloseStatus() && copilotSuggestions?.length) {
      onSendRequested(copilotSuggestions[0], true);
    }
  }, [copilotSuggestions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPromptSelected = (prompt: string) => {
    onSendRequested(prompt);
  };

  const onResetMessages = () => {
    resetMessages();
  };

  const finalSuggestions = useMemo(() => {
    const userQuestions = messages
      ?.filter((message) => message.role === ChatRole.user && !message.hidden)
      .map((message) => message.content);

    const topPromptSuggestions = topPrompts
      ?.map((prompt) => prompt.message)
      .filter((message) => !copilotSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase()))
      ?.slice(0, 2);

    const topUserSuggestions = userPrompts
      ?.map((prompt) => prompt.message)
      .filter(
        (message) =>
          !copilotSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase()) &&
          !topPromptSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase())
      )
      ?.slice(0, 5);

    const combinedSuggestions = copilotSuggestions?.concat(topPromptSuggestions)?.concat(topUserSuggestions);

    return hasUserAskedQuestion
      ? combinedSuggestions?.filter(
          (suggestion) => !userQuestions?.find((s) => s.toLowerCase() === suggestion.toLowerCase())
        )
      : combinedSuggestions;
  }, [copilotSuggestions, hasUserAskedQuestion, messages, topPrompts, userPrompts]);

  const rootClassNames = [classNames.root, className];
  const headerClassNames = [classNames.paneHeader];
  const messagesClassNames = [];

  if (largeHeaderText) {
    headerClassNames.push(classNames.largeHeaderText);
  }

  if (copilotMode === CopilotMode.Immersive) {
    messagesClassNames.push(classNames.immersive);
  }

  return (
    <div className={rootClassNames.join(" ")} style={style}>
      <CopilotCommandBar
        topPrompts={topPrompts}
        userPrompts={userPrompts}
        onPromptSelected={onPromptSelected}
        hideResizeButtons={hideResizeButtons}
        copilotMode={CopilotMode.Immersive}
      />
      {copilotPaneSize !== CopilotPaneSize.min ? (
        <>
          <div className={headerClassNames.join(" ")}>Commerce Radar Copilot</div>
          <div className={classNames.chatPane}>
            <Chat
              chatMessages={messages}
              userPhoto={userPhoto}
              loadingData={loadingData}
              convertMarkdown={true}
              suggestions={!loadingData && finalSuggestions}
              maxSuggestions={hasUserAskedQuestion ? copilotFollowupMessageCount : copilotInitialMessageCount}
              messagesClassName={messagesClassNames.join(" ")}
              onSendRequested={onSendRequested}
              onResetMessages={onResetMessages}
              onSuggestionClick={(suggestion) => onSendRequested(suggestion)}
            />
          </div>
        </>
      ) : (
        <div className={classNames.verticalText}>Commerce Radar Copilot</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  userPhoto: state.app.login_user_photo,
});

export default connect(mapStateToProps)(CopilotPane);

const initialAssistiveChatMessages = [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  { role: ChatRole.system, content: "What information you want to know about this data on the left?" },
];

const initialImmersiveChatMessages = [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  { role: ChatRole.system, content: "What information you want to know about from Commerce Radar?" },
];

const initialChatMessagesWithSuggestions = [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  {
    role: ChatRole.system,
    content:
      "Enter what you want to know about this data on the left, or select one of the following common questions.",
  },
];
