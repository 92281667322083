import React, { useContext } from "react";
import classNames from "./CopilotPane.module.scss";
import { CommandBar, ContextualMenuItemType, ICommandBarItemProps } from "@fluentui/react";
import { AppModuleContext } from "../common/AppModule";
import { CopilotMode, CopilotPaneSize } from "./CopilotPane";

export interface ICopilotCommandBarProps {
  onPromptSelected: (selectedPrompt: string) => void;
  topPrompts: any[];
  userPrompts: any[];
  hideResizeButtons?: boolean;
  copilotMode?: CopilotMode;
}

export const CopilotCommandBar = (props: ICopilotCommandBarProps) => {
  const { onPromptSelected, topPrompts, userPrompts, hideResizeButtons } = props;
  const { appModuleState, changeAppModuleState } = useContext(AppModuleContext);
  const { copilotPaneSize = CopilotPaneSize.normal } = appModuleState;

  const getCommandItems = (): ICommandBarItemProps[] => {
    let promptItems: ICommandBarItemProps[] = [
      { key: "myTopPromptsHeader", text: "Top Prompts By Me", itemType: ContextualMenuItemType.Header },
    ];

    if (userPrompts?.length) {
      userPrompts.slice(0, 10).forEach((prompt, index) =>
        promptItems.push({
          key: "myprompt" + index,
          text: prompt.message,
          title: prompt.message,
          onClick: () => onPromptSelected(prompt.message),
        })
      );
    } else {
      promptItems.push({ key: "", text: "(No prompt history is available)" });
    }

    if (topPrompts?.length) {
      let promptCount = 0,
        topPromptItems = [];

      for (let i = 0; i < topPrompts.length; i++) {
        let prompt = topPrompts[i];

        topPromptItems.push({
          key: "allprompt" + promptCount++,
          text: prompt.message,
          title: prompt.message,
          onClick: () => onPromptSelected(prompt.message),
        });

        if (promptCount >= 10) {
          break;
        }
      }

      if (topPromptItems.length > 0) {
        topPromptItems.unshift({
          key: "allTopPromptsHeader",
          text: "Top Prompts By Others",
          itemType: ContextualMenuItemType.Header,
        });

        promptItems = promptItems.concat(topPromptItems);
      }
    }

    return [
      {
        key: "feedback",
        iconProps: { iconName: "Feedback" },
        text: "Copilot Feedback",
        onClick: () => {
          window.open(
            "https://microsoft.sharepoint.com/:w:/t/RevenueBillingPlatforms/Ec-H_q98bZRNq5aB46gCCT0BZLfP9Q8ds8PWyqeMelIzBg?e=lL1AAj"
          );
        },
      },
      {
        key: "promptHistory",
        iconProps: { iconName: "History" },
        subMenuProps: {
          items: promptItems,
        },
      },
    ];
  };

  const onExpandToggle = () => {
    const nextPaneSize =
      copilotPaneSize === CopilotPaneSize.min
        ? CopilotPaneSize.normal
        : copilotPaneSize === CopilotPaneSize.normal
        ? CopilotPaneSize.large
        : CopilotPaneSize.max;

    changeAppModuleState({ copilotPaneSize: nextPaneSize });
  };

  const onCollapseToggle = () => {
    const nextPaneSize =
      copilotPaneSize === CopilotPaneSize.max
        ? CopilotPaneSize.large
        : copilotPaneSize === CopilotPaneSize.large
        ? CopilotPaneSize.normal
        : CopilotPaneSize.min;

    changeAppModuleState({ copilotPaneSize: nextPaneSize });
  };

  const resizeToggles = [];

  if (!hideResizeButtons) {
    if (copilotPaneSize !== CopilotPaneSize.max) {
      resizeToggles.push({
        key: "expandPane",
        iconProps: { iconName: "ChevronLeft" },
        ariaLabel: "Expand this pane",
        title: "Expand this pane",
        iconOnly: true,
        onClick: onExpandToggle,
      });
    }

    if (copilotPaneSize !== CopilotPaneSize.min) {
      resizeToggles.push({
        key: "collapsePane",
        iconProps: { iconName: "ChevronRight" },
        title: "Collapse this pane",
        ariaLabel: "Collapse this pane",
        iconOnly: true,
        onClick: onCollapseToggle,
      });
    }
  }

  const styles = {
    root: {
      minWidth: "100px",
    },
  };

  return (
    <CommandBar className={classNames.commandBar} styles={styles} items={resizeToggles} farItems={getCommandItems()} />
  );
};

export default CopilotCommandBar;
